import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import api from "@/hooks/useApi";
import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2 } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { z } from "zod";

const form_schema = z.object({
    name: z.string(),
    email: z.string().email(),
    phone_number: z.string()
})

export default function RealEstateForm() {
    const [loading, setLoading] = useState<boolean>(false)

    const form = useForm<z.infer<typeof form_schema>>({
        resolver: zodResolver(form_schema),
        defaultValues: {
            name: "",
            email: "",
            phone_number: ""
        }
    })

    const handleSubmit = async (data: z.infer<typeof form_schema>) => {
        setLoading(true)

        await api.post("/leads/store", { ...data, type: "real-estate" })
            .then(() => {
                toast.success("Cadastro realizado com sucesso! Entraremos em contato com você em breve.")
                form.reset()
            })
            .catch(() => {
                toast.error("Occoreu um erro inesperado ao tentar realizar o seu cadastro.")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div className="max-w-[500px] w-full rounded-xl p-[2px] bg-gradient-to-br from-[#0D508E] via-[#2076BD] to-[#92B5D2]">
            <Form {...form}>
                <form
                    id="real-estate-register-form"
                    onSubmit={form.handleSubmit(handleSubmit)}
                    className="space-y-4 w-full bg-white p-4 rounded-[calc(0.75rem-2px)]"
                >

                    <p className="font-semibold text-center mt-2 mb-4 text-2xl md:text-3xl lg:text-4xl text-[#0D509E]">
                        Formulário de Cadastro
                    </p>

                    <FormField
                        name="name"
                        control={form.control}
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel className="font-extralight">Nome completo *</FormLabel>
                                <FormControl>
                                    <Input
                                        className="bg-[#B3C5D4] focus-visible:ring-[#2076BD] rounded-xl bg-opacity-60 py-6"
                                        placeholder="Digite seu nome completo"
                                        disabled={loading}
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        name="email"
                        control={form.control}
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel className="font-extralight">Email *</FormLabel>
                                <FormControl>
                                    <Input
                                        className="bg-[#B3C5D4] focus-visible:ring-[#2076BD] rounded-xl bg-opacity-60 py-6"
                                        placeholder="Digite seu email"
                                        disabled={loading}
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        name="phone_number"
                        control={form.control}
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel className="font-extralight">Número de telefone *</FormLabel>
                                <FormControl>
                                    <Input
                                        className="bg-[#B3C5D4] focus-visible:ring-[#2076BD] rounded-xl bg-opacity-60 py-6"
                                        placeholder="Digite seu número de telefone"
                                        disabled={loading}
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <div className="flex justify-center py-4">
                        <Button
                            disabled={loading}
                            form="real-estate-register-form"
                            type="submit"
                            className="w-full text-lg rounded-xl py-6 px-8 bg-gradient-to-r from-[#033876] via-[#0D509E] to-[#556BF7] hover:filter hover:brightness-90 transition duration-300">
                            {loading ? <Loader2 className="animate-spin" /> : "Começar agora!"}
                        </Button>
                    </div>
                </form>
            </Form>
        </div>

    )
}