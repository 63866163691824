import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies(null, { path: '/' });

const api = axios.create({
    baseURL: import.meta.env.VITE_API_URL + "/api",
    headers: {
        'API-Key': import.meta.env.VITE_API_KEY,
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookies.get('AUQ-TOKEN')}`
    }
});

export default api;