import { Facebook, Instagram } from "lucide-react";
import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <footer className="w-full flex flex-col items-center px-4 mt-5">
            <ul className="flex flex-wrap justify-center gap-4 md:gap-6 py-4 text-lg font-semibold">
                <li className="hover:text-[#2076bd]">
                    <Link to={"/quartos-para-alugar-em-lisboa"}>Quartos em Lisboa</Link>
                </li>
                <li className="hover:text-[#2076bd]">
                    <Link to={"/propriedades"}>
                        Propriedades
                    </Link>
                </li>
                <li className="hover:text-[#2076bd]">
                    <Link to={"/quem-somos"}>
                        Quem somos
                    </Link>
                </li>
                <li className="hover:text-[#2076bd]">
                    <Link to={"/descubra-o-seu-inquilino-ideal"}>
                        Descubra o seu inquilino ideal
                    </Link>
                </li>
            </ul>
            <div className="flex gap-4">
                <Link aria-label="Ir para o Facebook da Alugar Um Quarto" to="https://www.facebook.com/alugarumquarto" target="_blank" rel="noopener noreferrer">
                    <Facebook size={"35"} className="hover:text-[#2276bc]" />
                </Link>
                <Link aria-label="Ir para o Instagram da Alugar Um Quarto" to="https://www.instagram.com/alugarumquarto" target="_blank" rel="noopener noreferrer">
                    <Instagram size={"35"} className="hover:text-[#e1306c]" />
                </Link>

            </div>
            <div className="text-center py-4">
                <p>©2024 Alugar Um Quarto. Todos os direitos reservados.</p>
            </div>
        </footer>
    )
}