import { useState, useEffect } from 'react';
import { AdvancedMarker, Map, Pin } from '@vis.gl/react-google-maps';

const containerStyle = {
    width: '100%',
    height: '400px',
};

export default function MapComponent({ address }: { address: string }) {
    const [coordinates, setCoordinates] = useState<{ lat: number, lng: number } | null>(null)
    const [mapError, setMapError] = useState<boolean>(false)

    const geocodeAddress = async (address: string) => {
        const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${import.meta.env.VITE_GOOGLE_MAPS_API_KEY}`
        );
        const data = await response.json();
        if (data.results.length > 0) {
            const location = data.results[0].geometry.location;
            setCoordinates(location); 
        } else {
            setMapError(true)
        }
    };

    useEffect(() => {
        geocodeAddress(address);
    }, [address]);

    if(mapError){
        return (
            <div>
                <p className='text-lg'>
                    Ocorreu um erro ao tentar carregar o mapa.
                </p>
            </div>
        )
    }

    return (
        <div>
            {coordinates ? (
                <Map
                    mapId={"1"}
                    style={containerStyle}
                    defaultCenter={coordinates}
                    defaultZoom={17}
                    gestureHandling={'greedy'}
                    disableDefaultUI={false}
                >
                    <AdvancedMarker
                        key={"roomAddress"}
                        position={coordinates}
                    >
                        <Pin background={'#2076bd'} glyphColor={'#fff'} borderColor={'#fff'} />
                    </AdvancedMarker>
                </Map>
            ) : (
                <p>Carregando mapa...</p>
            )}
        </div>
    );
}