import { useEffect, useState } from 'react';
import { Card, CardContent } from "@/components/ui/card";
import { Carousel, CarouselContent, CarouselItem, CarouselPrevious, CarouselNext } from "@/components/ui/carousel";
import { Skeleton } from "@/components/ui/skeleton";
import useMediaQuery from "@/hooks/useMediaQuery";
import ReactPlayer from "react-player";
import { useSearchParams } from "react-router-dom";
import { VideoOff } from 'lucide-react';

interface VideosSectionProps {
    videos: string[];
}

export default function VideosSection({ videos }: VideosSectionProps) {
    const is_desktop = useMediaQuery();
    const [searchParams, setSearchParams] = useSearchParams({video_index: "0"});
    const videoIndex = parseInt(searchParams.get('video_index') || '0', 10);

    useEffect(() => {
        if (!searchParams.get("video_index")) {
            setSearchParams({ view: "videos", video_index: "0" });
        }
    }, [searchParams, setSearchParams]);

    const handleThumbnailClick = (index: number) => {
        setSearchParams({ view: "videos", video_index: index.toString() });
    };

    if (!videos) {
        return (
            <div className='px-4 text-left w-full'>
                <div className='border-2 border-[#2076bd] p-2 border-dashed rounded-lg flex flex-col items-center justify-center text-lg lg:text-xl 2xl:text-2xl font-semibold'>
                    <VideoOff />
                    Esse quarto não possui vídeos para mostrar.
                </div>
            </div>
        )
    }

    if (is_desktop) {
        return (
            <div className="w-full flex flex-col items-center pb-5 px-2">
                <div className="max-w-6xl w-full">
                    {/* Vídeo principal grande */}
                    <div className="bg-black w-full rounded-lg h-[450px] md:h-[500px] lg:h-[550px] xl:h-[600px] 2xl:h-[650px] mb-4">
                        {videos[videoIndex] ? (
                            <VideoWithLoading
                                url={videos[videoIndex]}
                                className="rounded-lg h-full w-full object-cover"
                            />
                        ) : (
                            <Skeleton className="h-full w-full rounded-lg" />
                        )}
                    </div>

                    {/* Miniaturas com rolagem horizontal */}
                    <div className="w-full flex overflow-x-auto gap-2">
                        {videos.map((video, index) => (
                            <div
                                key={index}
                                className={`cursor-pointer rounded-lg border-2 p-1 bg-black
                                ${videoIndex === index ? 'border-[#2076bd]' : 'border-transparent'}`}
                                onClick={() => handleThumbnailClick(index)}
                            >
                                <VideoThumbnail url={video} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="relative w-full max-w-full">
            <Carousel>
                <CarouselContent>
                    {videos.map((video, index) => (
                        <CarouselItem key={index}>
                            <Card className="border-0">
                                <CardContent className="bg-black flex p-0 h-[280px] sm:h-[400px] items-center justify-center">
                                    <VideoWithLoading
                                        url={video}
                                        className="w-full h-full"
                                    />
                                </CardContent>
                            </Card>
                        </CarouselItem>
                    ))}
                </CarouselContent>
                <CarouselPrevious />
                <CarouselNext />
            </Carousel>
        </div>
    );
}

interface VideoProps {
    url: string;
    className: string;
}

function VideoWithLoading({ url, className }: VideoProps) {
    const [loading, setLoading] = useState(true);

    return (
        <>
            {loading && <Skeleton className={`${className}`} />}
            <div className={`h-full w-full ${loading && "hidden"}`}>
                <ReactPlayer
                    url={url}
                    className={`${className}`}
                    controls
                    width="100%"
                    height="100%"
                    onReady={() => setLoading(false)}
                />
            </div>
        </>
    );
}

interface VideoThumbnailProps {
    url: string;
}

function VideoThumbnail({ url }: VideoThumbnailProps) {
    return (
        <div className="relative w-full h-full">
            <ReactPlayer
                url={url}
                className="object-cover h-full w-full"
                controls={false}
                muted
                width={100}
                height={80}
            />
        </div>
    );
}