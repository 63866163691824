import ImageWithLoading from "@/components/ImageWithLoading"
import { Card, CardContent } from "@/components/ui/card"
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel"
import { Property } from "@/types/property"
import { useNavigate } from "react-router-dom"

export function PropertyCarousel({ property }: { property: Property }) {

  const navigate = useNavigate()

  return (
    <div className="relative w-full max-w-full">
      <Carousel className="">
        <CarouselContent>
          {property.media_content.images.map((image, index) => (
            <CarouselItem key={index}>
              <Card>
                <CardContent onClick={() => navigate(`/propriedades/${property.name.toLowerCase().replace(" ", "-")}?property_uuid=${property.uuid}`)} className="cursor-pointer flex aspect-square p-0 items-center justify-center">
                  <ImageWithLoading src={image} alt="Imagem da propriedade" className="w-full h-full rounded-lg" />
                </CardContent>
              </Card>
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
    </div>
  )
}
