import { useEffect, useState } from "react";
import { FilterModal } from "./components/FilterModal";
import { RoomCarousel } from "./components/RoomCarousel";
import { Room } from "@/types/room";
import api from "@/hooks/useApi";
import { ChevronLeft, ChevronRight, Loader2 } from "lucide-react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Helmet } from "react-helmet";

export interface Filter {
    property_uuid?: string;
    max_price?: string;
    status?: string;
}

export default function RoomsPage() {
    const [totalPages, setTotalPages] = useState<number>(1);
    const [rooms, setRooms] = useState<Room[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const [filter, setFilter] = useState<Filter>({
        property_uuid: "",
        status: "",
        max_price: ""
    });
    const current_page: number = Number(searchParams.get('pagination')) || 1;
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const path = location.pathname;

        if (!searchParams.get("pagination")) {
            setSearchParams({ pagination: "1" });
        }

        if (path === '/quartos-para-alugar-em-lisboa-ate-300-euros') {
            setFilter((prev) => ({ ...prev, max_price: '300' }));
        }
    }, [location.pathname, searchParams]);

    useEffect(() => {
        let parameter = "";
        if (filter.property_uuid) parameter += `&property_uuid=${filter.property_uuid}`;
        if (filter.max_price) parameter += `&max_price=${filter.max_price}`;
        if (filter.status) parameter += `&status=${filter.status}`;

        const fetchRooms = async () => {
            await api.get(`/website/rooms?pagination=${current_page}${parameter}`)
                .then(success => {
                    setRooms(success.data.rooms);
                    setTotalPages(success.data.total_pages);
                })
                .catch((error) => {
                    console.error("Error fetching rooms:", error);
                })
                .finally(() => {
                    setLoading(false);
                });
        };

        fetchRooms();
    }, [current_page, filter]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [location]);

    const handlePaginationChange = (new_page: number) => {
        setLoading(true);
        navigate(`?pagination=${new_page}`);
    };

    if (loading) {
        return (
            <div className="w-full min-h-screen flex items-center justify-center">
                <Loader2 className="animate-spin" />
            </div>
        );
    }

    const title = filter.max_price === '300'
        ? "Quartos para alugar em Lisboa até 300 euros"
        : "Quartos para alugar em Lisboa";

    const description = filter.max_price === '300'
        ? "Encontre os melhores quartos para alugar em Lisboa até 300 euros. Oferecemos opções de quartos confortáveis e bem localizados. Alugue o seu quarto em Lisboa até 300 euros de forma rápida, segura e acessível."
        : "Encontre os melhores quartos para alugar em Lisboa. Oferecemos opções de quartos confortáveis e bem localizados. Alugue o seu quarto em Lisboa de forma rápida, segura e acessível.";

    const keywords = filter.max_price === '300'
    ? "quartos para alugar em Lisboa até 300 euros, quartos para arrendar em Lisboa até 300 euros, quartos para alugar lisboa até 300 euros, quartos em Lisboa até 300 euros, arrendar quarto em Lisboa até 300 euros, alugar quarto em Lisboa até 300 euros, arrendamento em Lisboa até 300 euros, aluguel de quartos em Lisboa até 300 euros, quarto para alugar em Lisboa até 300 euros, quarto para arrendar em Lisboa até 300 euros, alugar quarto até 300 euros"
    : "quartos para alugar em Lisboa, quartos para arrendar em Lisboa, quartos para alugar lisboa, quartos em Lisboa, arrendar quarto em Lisboa, alugar quarto em Lisboa, arrendamento em Lisboa, aluguel de quartos em Lisboa, quarto para alugar em Lisboa, quarto para arrendar em Lisboa, alugar quarto";

    const canonical_url = filter.max_price === '300'
        ? "https://www.alugarumquarto.pt/quartos-para-alugar-em-lisboa-ate-300-euros"
        : "https://www.alugarumquarto.pt/quartos-para-alugar-em-lisboa";

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />

                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:url" content={canonical_url} />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Alugar Um Quarto" />
                <meta property="og:locale" content="pt_BR" />
                <meta property="og:image" content="https://api.alugarumquarto.pt/storage/website/logo-da-alugar-um-quarto.webp" />
                <meta property="og:image:type" content="image/webp" />
                <meta property="og:image:width" content="181" />
                <meta property="og:image:height" content="181" />

                <meta name="robots" content="index, follow" />

                <link rel="canonical" href={canonical_url} />
            </Helmet>
            <div className="py-10 px-4 flex flex-col items-center gap-5">
                <div className="flex flex-col items-center w-full max-w-[1600px] gap-5">
                    <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-center">{title}</h1>
                    <h2 className="text-center text-xl md:text-2xl lg:text-3xl">Veja abaixo todos os quartos para alugar em Lisboa disponíveis!</h2>
                    <div className="flex justify-start w-full">
                        <FilterModal filter={filter} setFilter={setFilter} current_page={current_page} setTotalPages={setTotalPages} setRooms={setRooms} />
                    </div>
                    {rooms.length === 0 ? (
                        <div className="p-4">
                            <p className="text-3xl font-semibold text-center">Nenhum quarto foi encontrado.</p>
                        </div>
                    ) : (
                        <div className="w-full flex flex-col items-center sm:items-start sm:grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
                            {rooms.map((room, index) => (
                                <div key={index} className="flex flex-col w-full sm:mt-3 sm:max-w-[320px] lg:max-w-[350px]">
                                    <RoomCarousel room={room} />
                                    <div className="pt-2">
                                        <h3 className="text-lg lg:text-xl font-bold">{room.name}</h3>
                                        <p className="lg:text-lg">{room.address}</p>
                                        <p className="font-semibold lg:text-lg">€{room.price.toString().replace(".", ",")} <span className="font-normal"> por mês</span></p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                {totalPages > 1 && (
                    <div className="flex justify-end items-center gap-3">
                        <Button
                            aria-label="Ir para página anterior"
                            variant={"outline"}
                            onClick={() => handlePaginationChange(current_page - 1)}
                            disabled={current_page === 1}>
                            <ChevronLeft />
                        </Button>
                        <p className="text-primary/70">{current_page}/{totalPages}</p>
                        <Button
                            aria-label="Ir para a próxima página"
                            variant={"outline"}
                            onClick={() => handlePaginationChange(current_page + 1)}
                            disabled={totalPages === current_page}>
                            <ChevronRight />
                        </Button>
                    </div>
                )}
            </div>
        </>
    );
}
