import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { Carousel, CarouselContent, CarouselItem, CarouselPrevious, CarouselNext } from "@/components/ui/carousel";
import { Sheet, SheetTrigger, SheetContent, SheetHeader, SheetTitle, SheetClose } from "@/components/ui/sheet";
import { Skeleton } from "@/components/ui/skeleton";
import useMediaQuery from "@/hooks/useMediaQuery";
import { Image, ImageOff, X } from "lucide-react";
import { useState } from "react";

interface ImagesSectionProps {
    images: string[];
}

export default function ImagesSection({ images }: ImagesSectionProps) {
    const is_desktop = useMediaQuery();

    if (!images) {
        return (
            <div className='px-4 text-left w-full'>
                <div className='border-2 border-[#2076bd] p-2 border-dashed rounded-lg flex flex-col items-center justify-center text-lg lg:text-xl 2xl:text-2xl font-semibold'>
                    <ImageOff />
                    Esse quarto não possui imagens para mostrar.
                </div>
            </div>
        )
    }

    if (is_desktop) {
        return (
            <div className="w-full flex flex-col items-center pb-5 px-2">
                <div className="relative max-w-6xl w-full flex gap-2">
                    <div className="grid grid-cols-3 gap-2 w-full">
                        {images.slice(0, 3).map((image, index) => (
                            <div key={index} className="h-full">
                                {image ? (
                                    <ImageWithLoading
                                        src={image}
                                        className={`rounded-lg h-full md:aspect-square w-full object-cover`}
                                        alt={`Imagem ${index + 1}`}
                                    />
                                ) : (
                                    <Skeleton className="h-full w-full rounded-lg" />
                                )}
                            </div>
                        ))}
                    </div>
                    {images.length > 3 &&
                        <Sheet>
                            <SheetTrigger asChild className="absolute bottom-2 right-2 flex gap-2">
                                <Button>
                                    <Image />
                                    Ver todas as imagens
                                </Button>
                            </SheetTrigger>
                            <SheetContent side={"bottom"}>
                                <SheetClose className="absolute top-4 right-4">
                                    <X className="text-black" />
                                </SheetClose>

                                <SheetHeader>
                                    <SheetTitle className="text-3xl mb-3">Imagens do quarto</SheetTitle>
                                    <div className="flex flex-wrap gap-3">
                                        {images.map((image, index) => (
                                            <ImageWithLoading key={index} src={image} alt="Imagem do quarto" className="w-[300px] h-[300px] aspect-square rounded-lg" />
                                        ))}
                                    </div>
                                </SheetHeader>
                            </SheetContent>
                        </Sheet>
                    }
                </div>
            </div>
        );
    }

    return (
        <div className="relative w-full max-w-full">
            <Carousel>
                <CarouselContent>
                    {images.map((image, index) => (
                        <CarouselItem key={index}>
                            <Card className="border-0">
                                <CardContent className="flex p-0 h-[280px] sm:h-[400px] items-center justify-center">
                                    <ImageWithLoading
                                        src={image}
                                        className="w-full h-full"
                                        alt="Imagem do quarto" />
                                </CardContent>
                            </Card>
                        </CarouselItem>
                    ))}
                </CarouselContent>
                <CarouselPrevious />
                <CarouselNext />
            </Carousel>
        </div>
    );
}

interface ImageProps {
    src: string;
    alt: string;
    className: string;
}

function ImageWithLoading({ src, alt, className }: ImageProps) {
    const [loading, setLoading] = useState(true);

    return (
        <>
            {loading && <Skeleton className="w-full h-full"></Skeleton>}
            <img
                src={src}
                alt={alt}
                className={`${className} ${loading ? 'hidden' : ''}`}
                onLoad={() => setLoading(false)}
            />
        </>
    );
}