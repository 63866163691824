import { AlertDialog, AlertDialogCancel, AlertDialogContent, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "@/components/ui/alert-dialog"
import { Button } from "@/components/ui/button"
import {
    Sheet,
    SheetClose,
    SheetContent,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from "@/components/ui/sheet"
import useMediaQuery from "@/hooks/useMediaQuery"
import { Loader2, SlidersHorizontal, X } from "lucide-react"
import { useEffect, useState } from "react"
import FilterForm from "./FilterForm"
import { Property } from "@/types/property"
import { Room } from "@/types/room"
import { Filter } from ".."

interface Props {
    setRooms: (data: Room[]) => void;
    current_page: number;
    filter: Filter;
    setFilter: (data: Filter) => void;
    setTotalPages: (data: number) => void;
}

export function FilterModal({ setRooms, current_page, filter, setFilter, setTotalPages }: Props) {
    const [properties, setProperties] = useState<Property[]>([])
    const [loading, _] = useState<boolean>(false)
    const [open, setOpen] = useState<boolean>(false)

    const is_desktop = useMediaQuery()

    const countActiveFilters = () => {
        const { property_uuid, max_price, status } = filter
        let activeFilters = 0;

        if (property_uuid !== "") activeFilters++;
        if (max_price !== "") activeFilters++;
        if (status !== "") activeFilters++;

        return activeFilters;
    };

    const [activeFiltersCount, setActiveFiltersCount] = useState<number>(countActiveFilters());

    useEffect(() => {
        const active_filters = countActiveFilters()

        setActiveFiltersCount(active_filters)
    }, [filter]);


    if (is_desktop) {
        return (
            <AlertDialog open={open} onOpenChange={setOpen}>
                <AlertDialogTrigger asChild>
                    <Button className="flex gap-2">
                        <SlidersHorizontal />
                        Filtros
                        ({activeFiltersCount})
                    </Button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                    <AlertDialogCancel className="p-0 absolute top-2 right-4 bg-transparent hover:bg-transparent border-0 w-auto">
                        <X />
                    </AlertDialogCancel>
                    {loading ?
                        <Loader2 className="animate-spin" />
                        :
                        <>
                            <AlertDialogHeader>
                                <AlertDialogTitle className="text-2xl">Filtros</AlertDialogTitle>
                            </AlertDialogHeader>
                            <FilterForm setProperties={setProperties} setTotalPages={setTotalPages} filter={filter} setFilter={setFilter} current_page={current_page} open={open} setOpen={setOpen} setRooms={setRooms} properties={properties} />
                        </>
                    }
                </AlertDialogContent>
            </AlertDialog>
        )
    }

    return (

        <Sheet open={open} onOpenChange={setOpen}>
            <SheetTrigger asChild>
                <Button className="flex gap-2">
                    <SlidersHorizontal />
                    Filtros
                    ({activeFiltersCount})
                </Button >
            </SheetTrigger >
            <SheetContent side={"bottom"}>
                {loading ?
                    <Loader2 className="animate-spin" />
                    :
                    <>
                        <SheetClose className="p-0 absolute top-2 right-4 bg-transparent hover:bg-transparent border-0 w-auto">
                            <X />
                        </SheetClose>
                        <SheetHeader className="mt-5">
                            <SheetTitle>Filtros</SheetTitle>
                        </SheetHeader>
                        <FilterForm setProperties={setProperties} setTotalPages={setTotalPages} filter={filter} setFilter={setFilter} current_page={current_page} open={open} setOpen={setOpen} setRooms={setRooms} properties={properties} />
                    </>
                }
            </SheetContent>
        </Sheet >
    )
}
