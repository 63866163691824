import { Helmet } from "react-helmet";

export default function RulesPage() {
    return (
        <>
            <Helmet>
                <title>Alugar Um Quarto | Regras</title>
            </Helmet>

            <div className="overflow-hidden">
                <iframe className="min-h-[75vh]" src={`https://drive.google.com/file/d/1IpXQcGlQhsu_gY-hYLukb7OmEF2_u0YJ/preview`} width="100%" height="100%" title="PDF de regras do Alugar Um Quarto" allow="autoplay" />
            </div>
        </>
    );
}