import { useState } from "react";
import { Skeleton } from "./ui/skeleton";

interface ImageProps {
    src: string;
    alt: string;
    className: string;
}

export default function ImageWithLoading({ src, alt, className }: ImageProps) {
    const [loading, setLoading] = useState(true);

    return (
        <>
            {loading && <Skeleton className="w-full h-full"></Skeleton>}
            <img
                src={src}
                alt={alt}
                className={`${className} ${loading ? 'hidden' : ''}`}
                onLoad={() => setLoading(false)}
            />
        </>
    );
}