import React, { useEffect, useState } from 'react';

interface ImageVariant {
    src: string;
    width: number;
    height: number;
}

interface ResponsiveImageProps {
    imageVariants: { [key: string]: ImageVariant};
    alt: string;
    className?: string;
    width?: number;
    height?: number;
    loading: "eager" | "lazy";
}

const ResponsiveImage: React.FC<ResponsiveImageProps> = ({ imageVariants, alt, className, loading }: ResponsiveImageProps) => {
    const [imageSize, setImageSize] = useState<ImageVariant | null>(null);

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            let selectedImageSize: ImageVariant;

            if (screenWidth <= 720) {
                selectedImageSize = imageVariants['480'];
            } else if (screenWidth <= 1080) {
                selectedImageSize = imageVariants['720'];
            } else {
                selectedImageSize = imageVariants['1080'];
            }

            setImageSize(selectedImageSize);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [imageVariants]);

    return (
        <>
            <img 
                src={imageSize?.src}
                alt={alt}
                className={className}
                width={imageSize?.width}
                height={imageSize?.height}
                loading={loading}
                
            />
        </>
    );
};

export default ResponsiveImage;