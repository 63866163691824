import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ResponsiveImage from '../../components/ResponsiveImage';
import { useEffect, useState } from "react";

export default function WelcomePage() {
    const imageVariants = {
        '480': {
            src: 'https://api.alugarumquarto.pt/storage/website/homem-sentado-na-cama-de-um-quarto-em-lisboa-480.webp',
            width: 480,
            height: 320
        },
        '720': {
            src: 'https://api.alugarumquarto.pt/storage/website/homem-sentado-na-cama-de-um-quarto-em-lisboa-720.webp',
            width: 720,
            height: 480
        },
        '1080': {
            src: 'https://api.alugarumquarto.pt/storage/website/homem-sentado-na-cama-de-um-quarto-em-lisboa-1080.webp',
            width: 1080,
            height: 720
        }
    }

    const getImageResolution = () => {
        const screenWidth = window.innerWidth;

        if (screenWidth < 720) {
            return "480";
        } else if (screenWidth >= 720 && screenWidth < 1080) {
            return "720";
        } else {
            return "1080";
        }
    };

    const [resolution, setResolution] = useState<string>(getImageResolution())

    useEffect(() => {
        const resolution = getImageResolution();
        setResolution(resolution)
    }, [window.innerWidth]);

    return (
        <>
            <Helmet>
                <title>Alugar Um Quarto em Lisboa</title>
                <meta name="description" content="Alugar um quarto em Lisboa nunca foi tão fácil! Oferecemos quartos confortáveis e bem localizados. Encontre e alugue o seu quarto ideal em Lisboa hoje mesmo." />
                <meta name="keywords" content="alugar um quarto em Lisboa, arrendar um quarto em Lisboa, quartos para alugar em Lisboa, quartos para alugar lisboa, quartos para arrendar em Lisboa, quartos para alugar em Lisboa até 300 euros, quartos para arrendar em Lisboa até 300 euros, alugar em Lisboa, arrendar em Lisboa, quartos em Lisboa, arrendamento de quartos em Lisboa" />

                <meta property="og:title" content="Alugar Um Quarto em Lisboa" />
                <meta property="og:description" content="Alugar um quarto em Lisboa nunca foi tão fácil! Oferecemos quartos confortáveis e bem localizados. Encontre e alugue o seu quarto ideal em Lisboa hoje mesmo." />
                <meta property="og:url" content="https://www.alugarumquarto.pt/" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Alugar Um Quarto" />
                <meta property="og:locale" content="pt_BR" />
                <meta property="og:image" content="https://api.alugarumquarto.pt/storage/website/logo-da-alugar-um-quarto.webp" />
                <meta property="og:image:type" content="image/webp" />
                <meta property="og:image:width" content="181" />
                <meta property="og:image:height" content="181" />

                <meta name="robots" content="index, follow" />

                <link rel="canonical" href="https://www.alugarumquarto.pt/" />
                <link rel="preload" href={`https://api.alugarumquarto.pt/storage/website/homem-sentado-na-cama-de-um-quarto-em-lisboa-${resolution}.webp`} as="image" />
            </Helmet>
            <div className="bg-[#2076bd] w-full flex flex-col items-center lg:flex-row">
                <div className="px-4 py-10 flex flex-col text-center lg:text-start lg:items-center lg:w-[50%] lg:justify-center">
                    <div className="max-w-[700px]">
                        <h1 className="text-4xl lg:text-5xl font-semibold text-white">
                            Já imaginou Alugar Um Quarto em Lisboa sem complicações?
                        </h1>
                        <h2 className="text-white text-xl lg:text-2xl font-semibold my-5">
                            Conheça a Alugar Um Quarto, a plataforma ideal para quem busca alugar um quarto em Lisboa. Encontre o quarto perfeito para você de forma rápida, segura e com preços acessíveis.
                        </h2>
                        <div className="flex justify-start lg:max-w-[80%]">
                            <Link id="show-all-rooms-homepage-button"
                                to={"/quartos-para-alugar-em-lisboa"}
                                className="bg-primary hover:bg-primary/70 text-primary-foreground w-full font-semibold py-3 text-xl rounded-lg text-center"
                            >
                                Ver todos os quartos em Lisboa!
                            </Link>
                        </div>
                    </div>
                </div>

                <ResponsiveImage
                    loading="eager"
                    imageVariants={imageVariants}
                    alt="Homem sentado na cama de um quarto em Lisboa."
                    className="object-cover w-full h-auto lg:w-1/2"
                />
            </div>
        </>
    )
}