import bed from "@/assets/images/cama.webp"
import people from "@/assets/images/pessoas.webp"
import bag_of_euro from "@/assets/images/saco-de-euro.webp"
import { Button } from "@/components/ui/button"
import { Facebook, Instagram, LinkedinIcon } from "lucide-react"
import { Link } from "react-router-dom"
import { FaWhatsapp } from "react-icons/fa";
import RealEstateForm from "./components/RealEstateForm"
import { useRef } from "react"
import { Helmet } from "react-helmet";

export default function RealEstatePage() {

    const form_ref = useRef<HTMLDivElement>(null)

    const scrollToForm = () => {
        if (form_ref.current) {
            const top = form_ref.current.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({ top: top - 100, behavior: 'smooth' }); 
        }
    };


    return (
        <>
            <Helmet>
                <title>Descubra o seu inquilino ideal!</title>
                <meta name="description" content="Descubra o seu inquilino ideal! Nós alugamos o seu imóvel e sublocamos em quartos individuais, cuidando de toda a gestão: seleção de inquilinos, administração dos pagamentos e manutenção do imóvel." />
                <meta name="keywords" content="inquilinos, alugar meu imovel, alugar meu imovel em lisboa, inquilinos para o meu imovel, como encontrar inquilinos, arrendar meu imovel" />
              
                <meta property="og:title" content="Descubra o seu inquilino ideal" />
                <meta property="og:description" content="Descubra o seu inquilino ideal! Nós alugamos o seu imóvel e sublocamos em quartos individuais, cuidando de toda a gestão: seleção de inquilinos, administração dos pagamentos e manutenção do imóvel." />
                <meta property="og:url" content="https://www.alugarumquarto.pt/descubra-o-seu-inquilino-ideal" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Alugar Um Quarto" />
                <meta property="og:locale" content="pt_BR" />
                <meta property="og:image" content="https://api.alugarumquarto.pt/storage/website/logo-da-alugar-um-quarto.webp" />
                <meta property="og:image:type" content="image/webp" />
                <meta property="og:image:width" content="181" />
                <meta property="og:image:height" content="181" />

                <meta name="robots" content="index, follow" />

                <link rel="canonical" href="https://www.alugarumquarto.pt/descubra-o-seu-inquilino-ideal" />
                <link rel="preload" href="https://api.alugarumquarto.pt/storage/website/real-estate/light-blue-background.webp" as="image" />
            </Helmet>

            <main className="flex flex-col items-center w-full">
                <div className="bg-[url('https://api.alugarumquarto.pt/storage/website/real-estate/light-blue-background.webp')] bg-no-repeat w-full p-10 py-14 lg:py-20 bg-cover flex flex-col items-center">
                    <h1 className="text-white text-center text-2xl md:text-3xl lg:text-4xl font-extralight">
                        Procurando seu inquilino <span className="font-bold">ideal</span>?
                        Conheça o seu iniquilo
                        <span className="font-bold"> Profissional</span>!
                    </h1>
                    <Button
                        onClick={scrollToForm}
                        className="text-[#2076BD] text-lg mt-3 px-8
                        font-bold py-4 bg-[#F3F5F4] hover:bg-[#F3F5F4]/70 shadow-inner shadow-black transition duration-300 rounded-xl">
                        Saiba mais!
                    </Button>
                </div>
                <section className="px-4 py-10 max-w-6xl">
                    <p className="text-center font-bold text-2xl md:text-3xl lg:text-4xl">
                        O que é um <span className="text-[#0D509E]">Inquilino Profissional</span> ?
                    </p>
                    <div className="text-center font-extralight md:text-lg lg:text-xl mt-5 flex flex-col gap-1">
                        <p>
                            Um "Inquilino Profissional" é a solução ideal para quem deseja alugar seu imóvel sem preocupações. Nós alugamos o seu imóvel e sublocamos em quartos individuais, cuidando de toda a gestão: seleção de inquilinos, administração dos pagamentos e manutenção do imóvel. O pagamento do aluguel é de total responsabilidade nossa, garantindo contratos seguros e aluguéis sempre em dia. Oferecemos tranquilidade ao proprietário, sem estresse, com a certeza de que o imóvel estará bem cuidado.
                        </p>
                    </div>
                </section>
                <section className="px-4 py-10 max-w-6xl">
                    <p className="text-center font-bold text-2xl md:text-3xl lg:text-4xl">
                        Agora saiba por que somos o seu <span className="text-[#033876]">Inquilino ideal</span>
                    </p>
                    <ul className="mt-5 space-y-5">
                        <li className="bg-[#A8C6DF] bg-opacity-[0.46] rounded-[25px] py-5 px-4 flex flex-col gap-3">
                            <p className="font-bold text-xl md:text-2xl lg:text-3xl">
                                Garantia de Aluguel em Dia
                            </p>
                            <p className="font-extralight">
                                O proprietário recebe o pagamento do aluguel de forma garantida e pontual, sem preocupações, pois somos responsáveis pelo repasse, independentemente da ocupação dos quartos.
                            </p>
                        </li>
                        <li className="bg-[#A8C6DF] bg-opacity-[0.46] rounded-[25px] py-5 px-4 flex flex-col gap-3">
                            <p className="font-bold text-xl md:text-2xl lg:text-3xl">
                                Gestão Completa do Imóvel
                            </p>
                            <p className="font-extralight">
                                Cuidamos de todo o processo de sublocação e administração, incluindo a seleção dos inquilinos, manutenção do imóvel e resolução de problemas, proporcionando total tranquilidade.
                            </p>
                        </li>
                        <li className="bg-[#A8C6DF] bg-opacity-[0.46] rounded-[25px] py-5 px-4 flex flex-col gap-3">
                            <p className="font-bold text-xl md:text-2xl lg:text-3xl">
                                Redução de Riscos e Burocracias
                            </p>
                            <p className="font-extralight">
                                Eliminamos a burocracia, com contratos seguros e eficientes. Além disso, assumimos a responsabilidade pelo imóvel, reduzindo os riscos para o proprietário.
                            </p>
                        </li>
                        <li className="bg-[#A8C6DF] bg-opacity-[0.46] rounded-[25px] py-5 px-4 flex flex-col gap-3">
                            <p className="font-bold text-xl md:text-2xl lg:text-3xl">
                                Manutenção do Imóvel
                            </p>
                            <p className="font-extralight">
                                Garantimos que o imóvel esteja sempre bem conservado e em perfeitas condições, fazendo reparos e manutenções necessárias durante o período de sublocação.
                            </p>
                        </li>
                        <li className="bg-[#A8C6DF] bg-opacity-[0.46] rounded-[25px] py-5 px-4 flex flex-col gap-3">
                            <p className="font-bold text-xl md:text-2xl lg:text-3xl">
                                Maior Segurança e Estabilidade
                            </p>
                            <p className="font-extralight">
                                Com a garantia de um contrato seguro e de longa duração, o proprietário tem a estabilidade de saber que o imóvel estará sempre rendendo, sem a necessidade de gerenciar locatários temporários.
                            </p>
                        </li>
                    </ul>
                    <div className="px-4 mt-5 flex justify-center items-center">
                        <Button
                            onClick={scrollToForm}
                            className="text-lg py-6 px-8 bg-gradient-to-r from-[#033876] via-[#0D509E] to-[#556BF7] hover:filter rounded-xl hover:brightness-90 transition duration-300">
                            Quero saber mais!
                        </Button>
                    </div>
                </section>
                <section className="px-4 py-5 max-w-6xl">
                    <p className="text-center font-bold text-2xl md:text-3xl lg:text-4xl">
                        Quem somos?
                    </p>
                    <div className="text-center font-extralight md:text-lg lg:text-xl mt-5 flex flex-col gap-3">
                        <p>
                            Somos uma empresa  especializada no arrendamento de quartos individuais de alta qualidade na Grande Lisboa, com opções em localidades como Reboleira, Pontinha, Famões e Corroios.. Desde 2015, oferecemos acomodações confortáveis e seguras, ideais para estudantes e profissionais expatriados que estão iniciando sua nova jornada em Portugal. Todos os nossos contratos são 100% legais, com emissão de recibos que proporcionam benefícios fiscais, como desconto no IRS.
                        </p>
                        <p>
                            Atendemos exclusivamente a uma pessoa por quarto, garantindo tranquilidade e privacidade. Nossos quartos são projetados para acomodar apenas uma pessoa, com cama de solteiro, e não aceitamos crianças, animais de estimação ou a presença de mais de um ocupante por quarto. Estamos focados principalmente em expatriados oriundos da CPLP, como brasileiros, angolanos, moçambicanos e são-tomenses, além de trabalharmos com outras nacionalidades, incluindo ucranianos, russos, canadenses e chilenos. Acreditamos que essa diversidade enriquece a experiência de todos os nossos inquilinos, promovendo um ambiente acolhedor e multicultural.
                        </p>
                        <p>
                            Se você busca um lugar seguro, confortável e administrado de forma profissional para morar em Portugal, nós temos a solução perfeita para você.
                        </p>
                    </div>
                </section>
                <div className="px-4 py-10 bg-[#D9D9D9] flex flex-col items-center bg-opacity-30 gap-4 w-full">
                    <div className="flex flex-col sm:flex-row sm:flex-wrap sm:justify-center gap-6">
                        <div className="flex gap-2 self-start">
                            <img src={bed} alt="Cama" />
                            <div>
                                <p className="font-bold text-lg">39 quartos</p>
                                <p className="font-extralight text-sm">em Portugal, em breve em novos lugares</p>
                            </div>
                        </div>
                        <div className="flex gap-2 self-start">
                            <img src={bag_of_euro} alt="Saco de euro" />
                            <div>
                                <p className="font-bold text-lg">€260</p>
                                <p className="font-extralight text-sm">preço médio dos quartos</p>
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <div className="flex gap-2 self-start">
                                <img src={people} alt="Pessoas" />
                                <div>
                                    <p className="font-bold text-lg">188 inquilinos</p>
                                    <p className="font-extralight text-sm">desde o início de 2016</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="px-4 py-10 max-w-6xl">
                    <p className="text-center font-bold text-2xl md:text-3xl lg:text-4xl">
                        Conheça o que nos motiva
                    </p>
                    <div className="text-center font-extralight md:text-lg lg:text-xl mt-5 flex flex-col gap-1">
                        <p>
                            No Alugar um Quarto, nossa motivação é ajudar quem chega a Portugal cheio de sonhos, mas sem um lugar para chamar de lar. Sabemos como é difícil mudar de país e enfrentar a incerteza de encontrar moradia. Por isso, criamos uma solução simples e acessível para quem precisa de um espaço seguro, confortável e rápido.
                        </p>
                        <p>
                            Queremos que cada pessoa que confia em nós encontre um ambiente acolhedor e transparente e sem surpresas. O que nos motiva é tornar essa nova etapa mais fácil, garantindo que você tenha onde começar sua vida em Portugal com tranquilidade.
                        </p>
                    </div>
                    <div className="px-4 mt-5 flex justify-center items-center">
                        <Button
                            onClick={scrollToForm}
                            className="rounded-xl text-lg py-6 px-8 bg-gradient-to-r from-[#033876] via-[#0D509E] to-[#556BF7] hover:filter hover:brightness-90 transition duration-300">
                            Quero começar agora!
                        </Button>
                    </div>
                </section>
                <section className="px-4 py-5 max-w-6xl">
                    <p className="text-center font-bold text-2xl md:text-3xl lg:text-4xl">
                        Qual o tipo de imoveis procuramos?
                    </p>
                    <div className="text-center font-extralight md:text-lg lg:text-xl mt-5 flex flex-col gap-3">
                        <p>
                            Estamos à procura de imóveis espaçosos que ofereçam conforto e conveniência para nossos inquilinos. Preferimos localizações com fácil acesso ao metrô e transporte público, idealmente a até 10 minutos a pé, além de proximidade a comércios, mercados e outros serviços essenciais. Buscamos também imóveis com 2 ou mais casas de banho, garantindo maior praticidade e conforto para quem utiliza os quartos individuais.
                        </p>
                        <p>
                            Além disso, imóveis que possam ser remodelados com pladur são ideais, pois permitem a otimização dos espaços, criando ambientes mais amplos e funcionais. Se o seu imóvel atende a esses critérios, entre em contato conosco e aproveite nossa gestão completa e tranquila.
                        </p>
                    </div>
                    <div className="px-4 mt-5 flex justify-center items-center">
                        <Button
                            onClick={scrollToForm}
                            className="rounded-xl text-lg py-6 px-8 bg-gradient-to-r from-[#033876] via-[#0D509E] to-[#556BF7] hover:filter hover:brightness-90 transition duration-300">
                            Eu tenho o imóvel ideal!
                        </Button>
                    </div>
                </section>
                <section className="px-4 py-10 max-w-6xl text-center">
                    <p className="text-[#0D509E] font-medium text-xl md:text-2xl lg:text-3xl">
                        Tem alguma dúvida ou precisa de mais informações?
                    </p>
                    <p className="mt-3 text-base md:text-lg lg:text-xl">
                        Estamos à disposição para ajudar! Ficaremos felizes em conversar com você e encontrar a melhor solução.
                    </p>
                    <div className="flex flex-col items-center mt-4 gap-2">
                        <p className="text-[#0D509E] lg:text-lg">Nossas redes sociais
                        </p>
                        <div className="flex gap-4">
                            <Link aria-label="Ir para o Facebook da Alugar Um Quarto" to="https://www.facebook.com/alugarumquarto" target="_blank" rel="noopener noreferrer">
                                <Facebook size={"30"} className="hover:text-[#2276bc]" />
                            </Link>
                            <Link aria-label="Ir para o Instagram da Alugar Um Quarto" to="https://www.instagram.com/alugarumquarto" target="_blank" rel="noopener noreferrer">
                                <Instagram size={"30"} className="hover:text-[#e1306c]" />
                            </Link>
                            <Link aria-label="Ir para o Instagram da Alugar Um Quarto" to="https://www.instagram.com/alugarumquarto" target="_blank" rel="noopener noreferrer">
                                <LinkedinIcon size={"30"} className="hover:text-[#2276bc]" />
                            </Link>

                        </div>
                    </div>
                    <div className="flex flex-col items-center mt-4 gap-2">
                        <p className="text-[#0D509E] lg:text-lg">Nosso meio de contato
                        </p>
                        <div className="flex gap-4">
                            <Link aria-label="Ir para o Facebook da Alugar Um Quarto" to={`https://wa.me/351961639797?text=${encodeURIComponent(`Ola, vim pelo site da Alugar Um Quarto.`)}`} target="_blank" rel="noopener noreferrer">
                                <FaWhatsapp size={"30"} className="hover:text-[#25D366]" />
                            </Link>
                        </div>
                    </div>
                </section>
                <section ref={form_ref} className="px-4 my-5 w-full flex justify-center">
                    <RealEstateForm />
                </section>
                <div className="bg-[url('https://api.alugarumquarto.pt/storage/website/real-estate/blue-couch.webp')] bg-no-repeat bg-cover w-full p-10 px-5 lg:py-20 flex flex-col items-center">
                    <h1 className="text-white text-center text-2xl md:text-3xl lg:text-4xl font-semibold">
                        Imóvel desocupado? Não se preocupe! Alugue com a gente e garanta renda continúa para sua propriedade.
                    </h1>
                    <Button
                        onClick={scrollToForm}
                        className="rounded-xl text-[#2076BD] text-lg mt-3 py-6 bg-[#F3F5F4] hover:bg-[#F3F5F4]/70 shadow-inner shadow-black transition duration-300">
                        Começar agora!
                    </Button>


                </div>
            </main>
        </>
    )
}